'use strict';

/**
 * This file represents the template for building out jQuery functionality. Simply
 * copy this file and rename to whatever the module is named (keep the .jquery.js filename,
 * so it's obvious which components are jquery based). Later it will be compiled
 * into the core javascript file and new components do not need to be added to the .info file.
 * ES6 syntax is available via the Babel transpiler, so feel free to use modern syntax
 * and features.
 *
 * Each "component" should live in its own file. This allows for separation of concerns and
 * ease of locating functionality, and is just generally good practice.
 *
 * This file will be all commented out so as not to tarnish the compiled javascript with
 * example or template code.
 *
 * Each file should begin with a header stub, just like this one, with a short description
 * of the component and an author so we know who to blame.
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

/*
(function ($) {
  'use strict';

  // add functionality here
})(jQuery);
*/

/**
 * This file represents the template for building out vanilla javascript functionality. Simply
 * copy this file and rename to whatever the module is named. Later it will be compiled
 * into the core javascript file and new components do not need to be added to the .info file.
 * ES6 syntax is available via the Babel transpiler, so feel free to use modern syntax
 * and features.
 *
 * Each "component" should live in its own file. This allows for separation of concerns and
 * ease of locating functionality, and is just generally good practice.
 *
 * This file will be all commented out so as not to tarnish the compiled javascript with
 * example or template code.
 *
 * Each file should begin with a header stub, just like this one, with a short description
 * of the component and an author so we know who to blame.
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

/*
(function () {
  'use strict';

  // add functionality here
})();
*/

/**
 * Detects whether or not the user's device has touch capabilities and
 * adds a class to the main html element for easy usage by the CSS.
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function () {
  'use strict';

  if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    document.querySelector('html').classList.add('touchevents');
  } else {
    document.querySelector('html').classList.add('no-touchevents');
  }
})();

/**
 * This file creates the sticky functionality for the header/menu so that it scrolls up and down.
 * To make it change colors add .sticky to the _header.scss and enjoy
 * @author Jess McClary <jess.mcclary@tricomb2b.com>
 */
(function ($) {
  'use strict';

  var topHeight = 100;
  var $header = $('#header');
  // Adding background color to header for scrolling

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > topHeight) {
      $header.addClass('sticky');
    } else {
      $header.removeClass('sticky');
    }
  });
})(jQuery);